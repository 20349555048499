import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { FaWhatsapp } from "react-icons/fa";

import Layout from "../components/layout";
import SEO from "../components/seo";
import NewsletterBanner from "../components/newsletterBanner";
import HowItWorksRow from "../components/howItWorksRow";
import DestinationsRow from "../components/destinationsRow";

import fertilitaSvg from "../images/icona_fertilita.svg";
// import odontoiatriaSvg from "../images/icona_odontoiatria.svg";
import rinoplasticaSvg from "../images/icona_rinoplastica.svg";
// import bariatricaSvg from "../images/icona_bariatrica.svg";
import trapiantoSvg from "../images/icona_trapianto.svg";

import handsSvg from "../images/hands_icon.svg";
import heartSvg from "../images/heart_icon.svg";
import ribbonSvg from "../images/ribbon_icon.svg";

import backgroundStillJpg from "../images/hero_banner.jpg";
import { stringToSlug } from "../utils/stringManipulation";

const query = graphql`
  query {
    allStrapiPage(
      limit: 4
      filter: { sezione: { in: ["blog", null] } }
      sort: { fields: publication_date, order: DESC }
    ) {
      nodes {
        id
        Titolo
        Permalink
        publication_date
        Copertina {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: AUTO, quality: 90, placeholder: BLURRED)
            }
          }
        }
      }
    }
    destinations: allStrapiPage(filter: { sezione: { eq: "destinazioni" } }) {
      nodes {
        id
        anteprima_testo
        Titolo
        Permalink
        Contenuto
        contenuto
        categoria {
          Name
        }
        publication_date
        posizione_copertina
        Copertina {
          alternativeText
          localFile {
            absolutePath
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 90
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <SEO title="Sterea Medicina e Viaggi" />

      <div className="sterea-center-column-wrapper overflow-hidden relative">
        <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-40 z-20"></div>
        <video
          playsInline
          autoPlay
          muted
          loop
          id="bgvid"
          poster={backgroundStillJpg}
          className="absolute home-page-video-height w-screen object-cover object-center"
        >
          <source
            media="(min-width:1280px)"
            src="https://res.cloudinary.com/mohststudio/video/upload/v1619707443/Sterea_Medical_travel_senza_watermark_FINAL_DESKTOP_1_do0jy5.mp4"
            type="video/mp4"
          />
          <source
            src="https://res.cloudinary.com/mohststudio/video/upload/v1619707443/Sterea_Medical_travel_senza_watermark_FINAL_DESKTOP_1_do0jy5.mp4"
            type="video/mp4"
          />
        </video>
        {/* <StaticImage
          src="../images/hero_banner.jpg"
          placeholder="blurred"
          layout="fullWidth"
          style={{ position: "absolute" }}
          className="w-full h-full z-10"
          objectFit="cover"
          alt="Immagine presa da un video dove è presente Laura Serzentaite, co-fondatrice della Sterea all'interno di uno studio medico"
        /> */}
        <div className="sterea-center-column home-page-video-height z-20 flex justify-center lg:justify-end items-center">
          <h1 className="w-1/2 text-white text-center lg:text-left lg:leading-tight text-xl lg:text-5xl font-bold">
            Il partner di fiducia per il tuo viaggio medicale
          </h1>
        </div>
      </div>

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column px-min py-12">
          <p className="text-center pb-9">
            Ti aiutiamo, dalla consultazione con lo specialista, alla
            prenotazione dell'hotel, ai trasferimenti. Un nostro consulente ti
            seguirà e ti accompagnerà per tutto il viaggio, tu dovrai solo
            pensare a te e goderti tutta l’esperienza in serenità.
          </p>
          <h2 className="text-center mb-6">Gli interventi</h2>
          <div className="flex flex-wrap justify-center">
            <div className="sterea-procedure-card-wrapper">
              <Link
                to={`/interventi/chirurgia-estetica`}
                className="sterea-procedure-card"
              >
                <img
                  src={rinoplasticaSvg}
                  className="mb-6 h-32"
                  alt="Icon di un naso non dritto, che rappresenta un intervento di rinoplastica, di chirurgia estetica/plastica"
                />
                <h4 className="mt-6 font-bold text-center">
                  Chirurgia Estetica
                </h4>
              </Link>
            </div>
            {/* <div className="sterea-procedure-card-wrapper">
              <div className="sterea-procedure-card">
                <img
                  src={bariatricaSvg}
                  className="mb-6 h-32"
                  alt="Icona di uno stomaco che viene ridotto, che rappresenta un intervento di chirurgia bariatrica"
                />
                <h4 className="mt-6 font-bold text-center">
                  Chirurgia Bariatrica
                </h4>
              </div>
            </div> */}
            <div className="sterea-procedure-card-wrapper">
              <Link
                to={`/interventi/trapianto-capelli`}
                className="sterea-procedure-card"
              >
                <img
                  src={trapiantoSvg}
                  className="mb-6 h-32"
                  alt="Icona di una testa piena di capelli invece che calva, che rappresenta un intervento di trapianto di capelli"
                />
                <h4 className="mt-6 font-bold text-center">
                  Trapianto Capelli
                </h4>
              </Link>
            </div>
            <div className="sterea-procedure-card-wrapper">
              <Link
                to={`/interventi/fertilita`}
                className="sterea-procedure-card"
              >
                <img
                  src={fertilitaSvg}
                  className="mb-6 h-32"
                  alt="Icona della pancia con un bambino dentro, all'interno di un cuore, che rappresenta gli interventi di IVF e fertilità"
                />
                <h4 className="mt-6 font-bold text-center">Fertilità</h4>
              </Link>
            </div>
            {/* <div className="sterea-procedure-card-wrapper">
              <div className="sterea-procedure-card">
                <img
                  src={odontoiatriaSvg}
                  className="mb-6 h-32 w-32"
                  alt="Icona dei denti di una bocca, che rappresenta un intervento di odontoiatria"
                />
                <h4 className="mt-6 font-bold text-center">Odontoiatria</h4>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <HowItWorksRow />

      <DestinationsRow destinations={data.destinations.nodes} />

      <div className="sterea-center-column-wrapper py-12 px-min bg-gray-100">
        <div className="sterea-center-column">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/3 px-3 mb-12 lg:mb-0 flex flex-col items-center">
              <img src={handsSvg} className="mb-6 h-16" />
              <p className="text-center">
                Per guidare e agevolare le vostre scelte, per annullare le
                distanze e le barriere linguistiche che si possono incontrare
                nel decidere di affrontare un intervento all'estero.
              </p>
            </div>
            <div className="w-full lg:w-1/3 px-3 mb-12 lg:mb-0 flex flex-col items-center">
              <img src={ribbonSvg} className="mb-6 h-16" />
              <p className="text-center">
                Contattare alcuni ospedali internazionali può essere difficile a
                causa delle differenze di fuso orario, delle barriere
                linguistiche e delle differenze culturali. Con Sterea avete la
                possibilità di superare tutto questo perchè disponiamo già di un
                sistema per rendere agevole il vostro viaggio medicale.
              </p>
            </div>
            <div className="w-full lg:w-1/3 px-3 flex flex-col items-center">
              <img src={heartSvg} className="mb-6 h-16" />
              <p className="text-center">
                Saremo sempre presenti per dissipare qualsiasi dubbio, dal primo
                consulto ai controlli post-operatori, perchè il nostro
                obbiettivo è la Vostra salute e la Vostra sicurezza.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="sterea-center-column-wrapper py-12 px-min sterea-blue-gradient text-white">
        <div className="sterea-center-column flex flex-wrap">
          <div className="w-full lg:w-1/2 lg:pr-6">
            <h2 className="mb-6">
              Richiedi subito il tuo preventivo gratuito in 24H
            </h2>
            <ul>
              <li>
                Compila il modulo qui{" "}
                <span className="hidden lg:inline">a lato</span>
                <span className="lg:hidden">sotto</span>
              </li>
              <li>
                <a href="tel:3792508156">
                  Chiamaci <span className="font-bold">+39 3792508156</span>
                </a>
              </li>
              <li>
                <a href="https://wa.me/393792508156">
                  Clicca qui per scrivici su{" "}
                  <span className="font-bold">WhatsApp</span>{" "}
                  <FaWhatsapp className="inline" />
                </a>
              </li>
            </ul>
            <div className="flex items-start lg:items-center mt-6 mb-6 lg:mb-0">
              <div className="text-center lg:text-left w-1/3 lg:w-1/4 mr-6 flex">
                <StaticImage
                  quality={90}
                  src="../images/laura_profile.png"
                  placeholder="none"
                  layout="constrained"
                  alt="Foto di profilo di Laura Serzentaite, co-fondatrice di Sterea Medicina e Viaggi"
                />
              </div>
              <div className="w-2/3 lg:w-3/4 text-white">
                Risponderemo a tutte
                le tue domande e ti consiglieremo per il meglio
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 lg:pl-6">
            <form className="flex flex-col text-black">
              <input
                type="text"
                placeholder="Nome"
                className="bg-white p-3 outline-none rounded mb-6"
              />
              <input
                type="text"
                placeholder="Email"
                className="bg-white p-3 outline-none rounded mb-6"
              />
              <textarea
                placeholder="Vorrei sapere..."
                className="bg-white p-3 h-24 outline-none rounded mb-6"
              />
              <div className="flex justify-center">
                <div className="sterea-yellow-button">Invia</div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column py-12 px-min">
          <h2 className="text-center mb-6">Articoli recenti</h2>
          <div className="flex flex-wrap justify-between">
            {data.allStrapiPage.nodes.map((post) => {
              return (
                Date.parse(post.publication_date) <= new Date() && (
                  <div className="w-full lg:w-1/2 py-md px-md">
                    <Link
                      to={`/post/${
                        (post.Permalink &&
                          post.Permalink != " " &&
                          post.Permalink) ||
                        stringToSlug(post.Titolo)
                      }`}
                    >
                      <div className="flex justify-start items-end overflow-hidden relative z-30 h-96 rounded-lg">
                        <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-40 z-20"></div>
                        <GatsbyImage
                          image={getImage(post.Copertina?.localFile)}
                          style={{ position: "absolute" }}
                          className="w-full h-full z-10"
                          objectFit="cover"
                          alt=""
                        />
                        <div className="p-4 relative z-20">
                          <h3 className="font-bold text-white">
                            {post.Titolo}
                          </h3>
                          <p className="text-sterea-bright-blue">
                            Leggi l'articolo
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              );
            })}
          </div>
          <div className="flex justify-center mt-6">
            <Link to={`/blog`} className="sterea-blue-purple-button">
              Vai al blog
            </Link>
          </div>
        </div>
      </div>

      <NewsletterBanner />
    </Layout>
  );
};

export default IndexPage;
